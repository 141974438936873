<template>
  <div class="content">
    <a>{{name}}</a>
  </div>
</template>
<script>

  export default {
    data(){
      return {
        name: this.$session.get('nome')
      }
    }
  };
</script>
