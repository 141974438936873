<template>

    <div>
      <base-table style=" overflow: hidden!important" :data="logisticas"
                thead-classes="text-primary">
        <template slot-scope="{row}">
          <td></td>
          <td >
            <div title="Ver perfil" @click="goToPerfilDriver(row.id_motorista)" class="text-white" style="text-decoration: none; color: inherit;">
              <h5 class="title text-white">{{row.nome_motorista }} </h5>
              <p class="small">{{row.referencia}}</p>
            </div>
          </td>

          <td class="td-actions text-right">
            <base-button type="link"  aria-label="edit button" >
              <i v-if="row.notReadCount === 0" title="Entrar no chat com o executivo" @click="goToChatDriver(row.id_motorista, row.key)" class="tim-icons icon-chat-33"></i>
              <i v-else title="Entrar no chat com o executivo" @click="goToChatDriver(row.id_motorista, row.key)" class="tim-icons icon-chat-33">{{row.notReadCount}}</i>
            </base-button>
          </td>

        </template>
    </base-table>

    </div>
</template>
<script>
  import {BaseTable} from '@/components'
  import firebase from "../../firebase";



  export default {
    components: {
      BaseTable
    },

    data(){
      return{
        db:firebase.ref("/logistica"),
        dbDriver:firebase.ref("/motorista"),
        dbChat:firebase.ref("/chat"),
        logisticas:[],

        totalChatNotRead:null,
        chatsNotRead:[],
        total:0,

        referenciaAtual:null,
        driverDetalhes: {
          key:null,
          email: null,
          nome: null,
          phone: null,
          token: null,
          classe: null,
          ano:null,
          marca: null,
          modelo: null,
          nif: null,
          placa: null
        },
      }
    },

    methods: {
      getChatNotRead(logisticaKey){
        let chat = [], total=0

        this.dbChat.child(logisticaKey).on('value',(snap)=>{

          snap.forEach((item) => {
            let key = item.key;

            this.dbChat.child(logisticaKey+'/'+key).on('value',(sn)=>{
              let dataChat = sn.val();
              if(dataChat.read === "false" && dataChat.type_user === "driver"){
                chat.push({
                  date: dataChat.date.substring(0,16),
                  // date: dataChat.date,
                  message: dataChat.message,
                  type_user: dataChat.type_user,
                });
              }
            })

          });
          total = chat.length;

        });
        return total
      },

      goToPerfilDriver(driverId) {
        this.$router.push('/perfil-do-motorista/'+driverId)
      },

      goToChatDriver(driverId, logisticaKey) {
        this.$router.push('/chat-executivo/'+ driverId+"/"+logisticaKey)
      },

      getLogisticsDriver(items) {
        let _logistics = []

        items.forEach((item) => {
          let key = item.key;
          let data = item.val();
          if(data.status!='concluido' &&data.status!='nconcluido' && data.status!='aguardando' && data.id_usuario===this.$session.get('key')){
            console.log(parseInt(this.getChatNotRead(key)))
            _logistics.push({
              key: key,
              referencia: data.referencia,
              nome_motorista: data.nome_motorista.split(" ")[0],
              id_motorista: data.id_motorista,
              status: data.status,
              notReadCount: parseInt(this.getChatNotRead(key))
            });
          }
        });

        this.logisticas = _logistics;
        this.total = _logistics.length;

        // console.log(this.total)

      },




    },

    mounted(){
      this.db.on("value", this.getLogisticsDriver);
      this.getChatNotRead()
    }
  }
</script>
