import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");

const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const LogisticaHistorico = () => import(/* webpackChunkName: "common" */ "@/pages/LogisticaHistorico.vue");
const LogisticaHistoricoNaoEntregues = () => import(/* webpackChunkName: "common" */ "@/pages/LogisticaHistoricoNaoEntregues.vue");
const LogisticaHistoricoParcial = () => import(/* webpackChunkName: "common" */ "@/pages/LogisticaHistoricoParcial.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Add_Logistica = () => import(/* webpackChunkName: "common" */ "@/pages/LogisticaForm.vue");
const Edit_Logistica = () => import(/* webpackChunkName: "common" */ "@/pages/LogisticaFormEdit.vue");
const List_Logistica = () => import(/* webpackChunkName: "common" */ "@/pages/LogisticaList.vue");
const Login = () => import(/* webpackChunkName: "common" */ "@/pages/auth-pages/login-2.vue");
const Register = () => import(/* webpackChunkName: "common" */ "@/pages/auth-pages/register.vue");

const Chat = () => import(/* webpackChunkName: "common" */ "@/pages/Chat/Chat.vue");
const Perfil_Motorista = () => import(/* webpackChunkName: "common" */ "@/pages/Motorista/MotoristaPerfil.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Maps
      },
      {
        path: "chat-executivo/:driverId/:logisticaKey",
        name: "Chat",
        component: Chat
      },
      {
        path: "perfil-do-motorista/:driverId",
        name: "Motorista",
        component: Perfil_Motorista
      },
      {
        path: "perfil-usuario",
        name: "Perfil",
        component: Profile
      },
      {
        path: "adicionar-logistica",
        name: "Adicionar Logistíca",
        component: Add_Logistica
      },
      {
        path: "editar-logistica/:logisticaEditKey",
        name: "Editar Logistíca",
        component: Edit_Logistica
      },
      {
        path: "lista-logistica-em-processo",
        name: "Logistícas agendandas",
        component: List_Logistica
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "historico-logistica-concluida",
        name: "Histórico de Logistica",
        component: LogisticaHistorico
      },
      {
        path: "historico-logistica-nao-entregues",
        name: "Histórico de Logistica não Entregues",
        component: LogisticaHistoricoNaoEntregues
      },
      {
        path: "historico-logistica-parciais",
        name: "Histórico de Logistica Entregues Parcialmente",
        component: LogisticaHistoricoParcial
      },
      // {
      //   path: "maps",
      //   name: "maps",
      //   component: Maps
      // },
      {
        path: "producao",
        name: "Produção",
        component: Dashboard
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList
      },
      {
        path: "auth",
        name: "auth",
        component: Login
      }
    ]
  },
  {
    path: '/auth',
    component: Login,
    redirect: "/auth",
    children: [
      {
        path: "login",
        name: "Login",
        component: Login
      },
    ]
  },
  {
    path: '/auth',
    component: Register,
    redirect: "/register",
    children: [
      {
        path: "register",
        name: "Registo",
        component: Register
      },
    ]
  },

  { path: "*", component: NotFound },
];


export default routes;
