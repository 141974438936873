
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import VueSession from 'vue-session'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import router from "./router/index";
import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import './registerServiceWorker'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import VuePlaceAutocomplete from 'vue-place-autocomplete';

import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import * as VueGoogleMaps from 'vue2-google-maps'




import GooglePlacesAutocompletePlugin from 'vue-better-google-places-autocomplete'
import { GooglePlacesAutocomplete } from 'vue-better-google-places-autocomplete'
import CustomGoogleAutocomplete from 'vue-custom-google-autocomplete'


Vue.component('google-places-autocomplete', GooglePlacesAutocomplete)
Vue.use(GooglePlacesAutocompletePlugin)
Vue.use(CustomGoogleAutocomplete)


Vue.use(VueAxios, axios)
// Vue.use(VuePlaceAutocomplete);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueSweetalert2);
Vue.use(VueSession)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDFehHeNx1VksCxQ4UTDQUPiL7f_hJ2_RE',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
})
Vue.prototype.$axios = axios

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
