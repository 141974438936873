<template >


<div >

  <!-- <div title="Google Maps">
    <div id="map" ref="map"></div>
  </div> -->
<!-- <div id="map" ref="map"></div> -->
    <!-- <iframe

        width="100%"
        height="100%"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="map"
        allowfullscreen
        scrolling="no"
        :src="`http://maps.google.com/maps?q=mesco,heuvelstraat 95,tilburg, nederland&layer=tc&t=m&z=16&source=embed&output=svembed`"
        style="filter: grayscale(0) contrast(1) opacity(1);">
    </iframe> -->
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2999.963048309044!2d-8.629599984657023!3d41.244362812812454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2467ae28857247%3A0x6c1e7568b40a74c8!2sFeira%20dos%20Sofas%20-%20Maia!5e0!3m2!1spt-PT!2smz!4v1643959782338!5m2!1spt-PT!2smz?z=16" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

    <side-bar >
      <template slot="links" >
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/adicionar-logistica" :name="$t('sidebar.adicionar')" icon="tim-icons icon-simple-add"/>
        <sidebar-link to="/lista-logistica-em-processo" :name="$t('sidebar.logisticas')" icon="tim-icons icon-delivery-fast"/>
        <sidebar-link to="/historico-logistica-concluida" :name="$t('sidebar.historico')" icon="tim-icons icon-paper"/>
        <sidebar-link to="/historico-logistica-nao-entregues" :name="$t('sidebar.historico_nao_entregues')" icon="tim-icons icon-paper"/>
       <sidebar-link to="/historico-logistica-parciais" :name="$t('sidebar.historico_parciais')" icon="tim-icons icon-paper"/>
        <sidebar-link to="/producao" :name="$t('sidebar.producao')" icon="tim-icons icon-chart-bar-32"/>
        <base-button class="ml-4 mt-5 text-white btn btn-rounded"   slot="footer" @click="logout" type="" fill>Sair</base-button>
      </template>
    </side-bar>
    <div class="main-panel" >
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"   >
      </dashboard-content>

      <content-footer></content-footer>

    </div>

    <side-bar-right class="danger">
      <template slot="links">
        <div class="table-full-width table-responsive">
            <Motoristas></Motoristas>
          </div>
      </template>
    </side-bar-right>


  </div>




</template>
<style lang="scss">
</style>
<script>

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import SideBar_right from '../../components/SidebarPlugin/SideBar_right.vue';
import FullScreenBackgroundMap from 'vue-fullscreen-background-map';

import Motoristas from '../../pages/Motorista/MotoristasList.vue';

// Style
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

export default {
  components: {
    FullScreenBackgroundMap,
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    SideBar_right,
    Motoristas,
  },
  data(){
     return {
      logout_:"Logout"
    }
  },
   beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/auth/login')
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    refresh(){
      window.location.reload();
    },
    logout(){
      this.$session.destroy()
      this.$router.push('/auth/login')
      this.refresh()
    },

  },

};
</script>
<style>
    iframe {
        border: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
    }
#map{
        border: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        display: inline-flex;
        width: 100%;
        height: 100%;


  }
.este{
  background: rgb(235, 71, 120);
}
</style>

		<style>
				#map-canvas {
          position: absolute;
          height: 100%;
          width: 100%;
				  bottom: 0;
				  left: 0;
				  right: 0;
				}
        #mapbac{
          height:1000px;
          background: gray;
        }
				#container {
				  z-index: 100;
				  position: relative;
				}
		</style>

    <style>

.card{
  height: auto;
  width: 90%;
  border-radius: 15px !important;
  border-bottom-color: #d48919;
  background-color: rgb(255, 255, 255) !important;
}
.contacts_body{
  padding:  0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body{
  overflow-y: auto;
}
.card-header{
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer{
border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container{
  align-content: center;
}
.search{
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
}
.search:focus{
   box-shadow:none !important;
 outline:0px !important;
}
.type_msg{
  background-color: rgba(253, 253, 253, 0.3) !important;
  border:0 !important;
  color:rgb(0, 0, 0) !important;
  font-size: 20px;
  font-weight: bold;
  height: 60px !important;
  overflow-y: auto;
}
  .type_msg:focus{
   box-shadow:none !important;
 outline:0px !important;
}
.attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn{
border-radius: 0 15px 15px 0 !important;
background-color: rgb(255, 255, 255) !important;
  border:0 !important;
  border-color: #ffffff;
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
}
.search_btn{
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts{
  list-style: none;
  padding: 0;
}
.contacts li{
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.active{
  background-color: rgba(0,0,0,0.3);
}
.user_img{
  height: 70px;
  width: 70px;
  border:1.5px solid #f5f6fa;
}
.user_img_msg{
  height: 40px;
  width: 40px;
  border:1.5px solid #f5f6fa;
}
.img_cont{
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg{
  height: 40px;
  width: 40px;
}
.online_icon{
position: absolute;
height: 15px;
width:15px;
background-color: #4cd137;
border-radius: 50%;
bottom: 0.2em;
right: 0.4em;
border:1.5px solid white;
}
.offline{
background-color: #c23616 !important;
}
.user_info{
margin-top: auto;
margin-bottom: auto;
margin-left: 15px;
}
.user_info span{
font-size: 20px;
color: white;
}
.user_info p{
font-size: 10px;
color: rgba(255,255,255,0.6);
}
.video_cam{
margin-left: 50px;
margin-top: 5px;
}
.video_cam span{
color: white;
font-size: 20px;
cursor: pointer;
margin-right: 20px;
}
.msg_cotainer{
margin-top: auto;
margin-bottom: auto;
margin-left: 10px;
border-radius: 25px;
background-color: #82ccdd;
padding: 10px;
padding-right: 80px;
position: relative;
}
.msg_cotainer_send{
margin-top: auto;
margin-bottom: auto;
margin-right: 10px;
border-radius: 25px;
background-color: #78e08f;
padding: 10px;
padding-left: 80px;
position: relative;
}
.msg_time{
position: absolute;
left: 0;
bottom: -15px;
color: rgb(0, 0, 0);
font-size: 12px;
}
.msg_time_send{
padding-top: 15px;
position: absolute;
right:0;
bottom: -15px;
color: rgb(0, 0, 0);
font-size: 12px;
}
.msg_head{
position: relative;
}
#action_menu_btn{
position: absolute;
right: 10px;
top: 10px;
color: white;
cursor: pointer;
font-size: 20px;
}
.action_menu{
z-index: 1;
position: absolute;
padding: 15px 0;
background-color: rgb(119, 118, 105);
color: white;
border-radius: 15px;
top: 30px;
right: 15px;
display: none;
}
.action_menu ul{
list-style: none;
padding: 0;
margin: 0;
}
.action_menu ul li{
width: 100%;
padding: 10px 15px;
margin-bottom: 5px;
}
.action_menu ul li i{
padding-right: 10px;
}
.action_menu ul li:hover{
cursor: pointer;
background-color: rgba(0, 0, 0, 0.973);
}
@media(max-width: 576px){
.contacts_card{
margin-bottom: 15px !important;
}
}
/* width */
::-webkit-scrollbar {
width: 10px;
}
/* Track */
 ::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey;
border-radius: 10px;
}
/* Handle */
 ::-webkit-scrollbar-thumb {
background: #7F7FD5;
border-radius: 10px;
}
/* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
background: #5454b6;
}

</style>

