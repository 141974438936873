import   firebase from "firebase";
import "firebase/database";

let config = {
  apiKey: "AIzaSyDFehHeNx1VksCxQ4UTDQUPiL7f_hJ2_RE",
	authDomain: "iklogo.firebaseapp.com",
	databaseURL: "https://iklogo-default-rtdb.firebaseio.com",
	projectId: "iklogo",
	storageBucket: "iklogo.appspot.com",
	messagingSenderId: "436224948921",
	appId: "1:436224948921:web:9538462e2c8943b912f968",
	measurementId: "G-0SN5B7MT1Y"
};

firebase.initializeApp(config);

export default firebase.database();