<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="#">
            iK Business
          </a>
        </li>
        <li class="nav-item">
            © {{year}} criado  por
            <a href="https://www.iktechsolutions.com" target="_blank" rel="noopener">iK Tech Solutions</a>

        </li>
      </ul>

    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
